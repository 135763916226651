import Button from "react-bootstrap/Button";
import styled from "styled-components";

const LightBlueButton = styled(Button)`
  background-color: #e6f5f9;
  border-color: #e6f5f9;
  border-radius: 12px;
  outline: none;
  &:hover,
  &:focus {
    background-color: #21afcc;
    border-color: #21afcc;
    color: white;
    outline: none;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export default LightBlueButton;
