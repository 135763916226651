import * as React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import PrimaryButton from "../styles/styled-component/PrimaryButton";
import { section, activationTitle, whiteOval } from "./activation.module.css";

const Activation = ({ title, buttonText }) => {
  const renderButton = () => {
    if (buttonText) {
      return (
        <PrimaryButton>
          <Link to="/contact">{buttonText}</Link>
        </PrimaryButton>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div className={section}>
        <div className={whiteOval}></div>
        <Container>
          <h4 style={{ color: "#0097be" }}>Ready to get started?</h4>
          <h1 className={activationTitle}>{title}</h1>
          <p>
            Questions? Call us at{" "}
            <a href="tel:0034871559345" style={{ color: "#0097be" }}>
              +34 871 55 93 45
            </a>{" "}
            or email{" "}
            <a href="mailto:hello@floatist.com" style={{ color: "#0097be" }}>
              hello@floatist.com
            </a>
          </p>
          {renderButton()}
        </Container>
      </div>
    </>
  );
};

export default Activation;
